import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import Login from "./components/login/Login";
import {CustomToast} from "./components/toast/CustomToast";
import Header from "./components/header/Header";
import SiderBar from "./components/siderBar/SiderBar";
import Footer from "./components/footer/Footer";
import Meeting from "./components/meeting/Meeting";
import CentrePartenaires from "./components/centrePartenaires/CentrePartenaires";
import ListServices from "./components/listServices/ListServices";
import Contact from "./components/contact/Contact";
import Profile from "./components/profile/Profile";
import TakeMeeting from "./components/takeMeeting/TakeMeeting";
import CarteLeaflet from "./components/carte/CarteLeaflet";
import CentrePartenairesId from "./components/centrePartenairesId/CentrePartenairesId";
import ListArticles from "./components/articles/listArticles/ListArticles";
import ReadArticle from "./components/articles/readArticle/ReadArticle";
import ErrorClient from "./components/error/404/ErrorClient";


const route = createBrowserRouter([
  {
    path: '/',
    children:[
      {
        path: '',
        element: <>
          <CustomToast />
          <Login />
        </>
      },
      {
        path: 'login',
        element: <>
          <CustomToast />
          <Login />
        </>
      },
      {
        path: 'home',
        element: <Root />,
        errorElement: <Error />,
        children: [
          {
            path: 'meeting',
            element: <Meeting />
          },
          {
            path: 'cp',
            children:[
              {
                path: '',
                element: <CentrePartenaires />
              },
              {
                path: ':id',
                element: <CentrePartenairesId />
              }
            ]
          },
          {
            path: 'list/:id',
            children:[
              {
                path: '',
                element: <ListServices />
              },
              {
                path: 'take-meeting',
                element: <TakeMeeting />
              }
            ]
          },
          {
            path: 'contact',
            element: <Contact />
          },
          {
            path: 'profile',
            element: <Profile />
          },,
          {
            path: 'carte',
            element: <CarteLeaflet />
          },
          {
            path: 'work/:name',
            element: <ListArticles />
          },
          {
            path: 'read/:id',
            element: <ReadArticle />
          }
        ]

      }
    ]
  }
]);

function Root() {
  return <>
    <Header />
    <SiderBar />
    <>
      <CustomToast />
      <Outlet />
    </>
    <Footer />
  </>;

}

function Error() {
  return <>
    <Header />
    <>
      <ErrorClient />
    </>
    <Footer />
    {/* <Header />
    <>
      <ErrorClient />
    </>
    <Footer />*/}
  </>

}
function App() {
  return <RouterProvider router={route} />;
}
export default App;
