import React, {useEffect} from "react";
import {isUserConnect, useTitle} from "../../utils/Utils";
import {useSelector} from "react-redux";

export default function Profile(){
    useTitle("Profile");
    isUserConnect();
    const {user, accessToken} = useSelector(state => state.authUser);
    function checkIfUserIsConnect() {
        window.location='/';
    }
    useEffect(
        ()=>{
            if(user===null)
                checkIfUserIsConnect();
        },[]
    )

    return <main id="main" className="main">

        <div className="pagetitle">
            <h1>Profile</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Profil</li>
                </ol>
            </nav>
        </div>
        <section className="section profile">
            <div className="row">
                <div className="col-xl-4">
                    <div className="card">
                        <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                            <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" />
                                <h2>{(user===null) ? "" : user.nom.toUpperCase()}</h2>
                                <h3>{(user===null) ? "" : user.prenom}</h3>
                                <div id="status"
                                     style={
                                    {
                                        width: '25px',
                                        height: '25px',
                                        borderRadius: '50%',
                                        backgroundColor: user.status==="ACTIVE" ? 'rgba(19, 128, 0, 0.652)' : 'red'

                                    }
                                     }></div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8">
                    <div className="card">
                        <div className="card-body pt-3">
                            <ul className="nav nav-tabs nav-tabs-bordered">
                                <li className="nav-item">
                                    <button className="nav-link active" data-bs-toggle="tab"
                                            data-bs-target="#profile-overview">Informations
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content pt-2">
                                <div className="tab-pane fade show active profile-overview" id="profile-overview">
                                    <h5 className="card-title">Details du Profil</h5>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label ">Nom</div>
                                        <div className="col-lg-9 col-md-8">{(user===null) ? "" : user.nom.toUpperCase()+" "+ (user===null) ? "" : user.prenom}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label">Numero de Telephone</div>
                                        <div className="col-lg-9 col-md-8">{(user===null) ? "" : user.numero_telephone}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label">Email</div>
                                        <div className="col-lg-9 col-md-8">{(user===null) ? "" : user.email==null ? "none" : user.email}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label">Login</div>
                                        <div className="col-lg-9 col-md-8">{(user===null) ? "" : user.code}</div>
                                    </div>
                                </div>
                                <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
                                    <form>
                                        <div className="row mb-3">
                                            <label htmlFor="profileImage" className="col-md-4 col-lg-3 col-form-label">Photo
                                                de Profil</label>
                                            <div className="col-md-8 col-lg-9">
                                                <img src="assets/img/profile-img.jpg" alt="Profile" />
                                                    <div className="pt-2">
                                                        <a className="btn btn-primary btn-sm"
                                                           title="Upload new profile image"><i
                                                            className="bi bi-upload"></i></a>
                                                        <a className="btn btn-danger btn-sm"
                                                           title="Remove my profile image"><i
                                                            className="bi bi-trash"></i></a>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Nom</label>
                                            <div className="col-md-8 col-lg-9">
                                                <input name="fullName" type="text" className="form-control" id="fullName" value="Emi Jackson" />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <label htmlFor="about" className="col-md-4 col-lg-3 col-form-label">A Propos
                                                de moi</label>
                                            <div className="col-md-8 col-lg-9">
                                                <textarea name="about" className="form-control" id="about" style={
                                                    {
                                                        height: '100px'
                                                    }
                                                }>...</textarea>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="Phone" className="col-md-4 col-lg-3 col-form-label">Numero de Telephone</label>
                                            <div className="col-md-8 col-lg-9">
                                                <input name="phone" type="tel" className="form-control" id="Phone" value="69xxxxxxx" />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">Email</label>
                                            <div className="col-md-8 col-lg-9">
                                                <input name="email" type="email" className="form-control" id="Email" value="e.jocky@example.com" />
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary">Enregistrer les
                                                modifications
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="tab-pane fade pt-3" id="profile-change-password">
                                    <form>
                                        <div className="row mb-3">
                                            <label htmlFor="currentPassword"
                                                   className="col-md-4 col-lg-3 col-form-label">Mot de passe actuel</label>
                                            <div className="col-md-8 col-lg-9">
                                                <input name="password" type="password" className="form-control" id="currentPassword" />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="newPassword" className="col-md-4 col-lg-3 col-form-label">Nouveau mot de passe</label>
                                            <div className="col-md-8 col-lg-9">
                                                <input name="newpassword" type="password" className="form-control" id="newPassword" />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="renewPassword" className="col-md-4 col-lg-3 col-form-label">Confirmez le mot de passe</label>
                                            <div className="col-md-8 col-lg-9">
                                                <input name="renewpassword" type="password" className="form-control" id="renewPassword" />
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary">Changer le mot de passe
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>;
}