import React, {useEffect} from "react";
import {isUserConnect, useTitle} from "../../utils/Utils";
import {toastClick} from "../toast/CustomToast";
import {useSelector} from "react-redux";

export default function Contact(){
    useTitle("Contact");
    isUserConnect();
    const {user, accessToken} = useSelector(state => state.authUser);

    function checkIfUserIsConnect() {
        window.location='/';
    }
    const contactUsSubmit = (event)=>{
        event.preventDefault();
        toastClick("Non disponible","info");
    }
    useEffect(
        ()=>{
            if(user===null)
                checkIfUserIsConnect();
            else {
                let preloader = document.getElementById('preloader');
                preloader.classList.remove("preloader-deactivate");
                setTimeout(()=>{
                    preloader.classList.add("preloader-deactivate");
                },2500)
            }
        },[]
    )
    return <main id="main" className="main">
        <div className="pagetitle">
            <h1>Contact</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Contact</li>
                </ol>
            </nav>
        </div>
        <section className="section contact">
            <div className="row gy-4">
                <div className="col-xl-6">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="info-box card">
                                <i className="bi bi-telephone"></i>
                                <h3>Call Us</h3>
                                <p>+237 69xx-xxx-xxx</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="info-box card">
                                <i className="bi bi-envelope"></i>
                                <h3>Email</h3>
                                <p>youthfp@youthfp.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="card p-4">
                        <form className="php-email-form" onSubmit={contactUsSubmit}>
                            <div className="row gy-4">
                                <div className="col-md-6">
                                    <input type="text" name="name" className="form-control" placeholder="Votre Nom" required />
                                </div>
                                <div className="col-md-6 ">
                                    <input type="email" className="form-control" name="email" placeholder="Votre Mail" required />
                                </div>
                                <div className="col-md-12">
                                    <input type="text" className="form-control" name="subject" placeholder="Suject" required />
                                </div>
                                <div className="col-md-12">
                                    <textarea className="form-control" name="message" rows="6" placeholder="Message" required></textarea>
                                </div>
                                <div className="col-md-12 text-center">
                                    <div className="loading">Loading</div>
                                    <div className="error-message"></div>
                                    <div className="sent-message">Cotre message</div>
                                    <button type="submit">Envois</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </main>;
}