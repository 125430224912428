import React, {useEffect, useState} from "react";
import {isUserConnect, useTitle} from "../../utils/Utils";
import {useSelector} from "react-redux";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {useParams} from "react-router-dom";
import image from "../img/Health-Insurance.jpg";

export default function CentrePartenairesId(){
    const {id} = useParams();
    const [body, setBody]= useState([]);
    useTitle("Centre Partenaire");
    isUserConnect();
    const {user, accessToken} = useSelector(state => state.authUser);
    function checkIfUserIsConnect() {
        window.location='/';
    }
    const fetchCP = () => {
        let errorMessage = document.getElementById('error');
        let preloader = document.getElementById('preloader');
        preloader.classList.remove("preloader-deactivate");

        axiosInstance().get('service/list-by-cp-id/'+id)
            .then(function (response) {

                let data = response.data;
                if((data == null)||(data.length===0)){
                    errorMessage.style.display = 'block';
                }else{
                    let fetchedData = [];
                    for (let index = 0; index < data.length; index++) {
                        fetchedData.push(
                            <tr style={
                                {
                                    minHeight: '230px',
                                    maxHeight: '230px'
                                }
                            }>
                                <td style={
                                    {
                                        width: '230px',
                                        padding: '0'
                                    }
                                }>
                                    <img src={image} width="230px" height="230px"  style={
                                        {
                                            minHeight: '230px',
                                            maxHeight: '230px'
                                        }
                                    } />
                                </td>
                                <td>
                                    <div>
                                        <p><strong>Nom du Service: </strong>{data[index].libelle} </p>
                                        <p><strong>Centre Partenaire: </strong>{data[index].centrePartenaire.nom}</p>
                                        <p><strong>Telephone: </strong>{data[index].centrePartenaire.telephone}</p>
                                        <p><strong>localisation: </strong>{data[index].centrePartenaire.location}</p>
                                        <div><a href={"/home/list/"+id} className="btn">Services</a></div>

                                    </div>
                                </td>

                            </tr>
                        )
                    }
                    errorMessage.style.display = 'none';
                    setBody(fetchedData);
                    preloader.classList.add("preloader-deactivate");
                }
            })
            .catch(function (error) {
                errorMessage.style.display = 'none';
                preloader.classList.add("preloader-deactivate");
            });

    };

    useEffect(
        ()=>{
            if(user===null)
                checkIfUserIsConnect();
            else
                fetchCP();
        },[]
    )

    return <main id="main" className="main">
        <div className="pagetitle">
            <h1>Centre partenaire</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Centre partenaire</li>
                </ol>
            </nav>
        </div>
        <section className="section dashboard">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="card top-selling overflow-auto">
                                <div className="card-body pb-0">
                                    <h5 className="card-title">Centres Partenaires <span></span></h5>
                                    <table className="table table-borderless">
                                        <tbody>
                                        {body}

                                        </tbody>
                                    </table>
                                    <div id="error">
                                        <p>Ce partenaire n'existe pas / plus</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>;

}