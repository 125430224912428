import React from "react";

export default function Footer(){
    return <footer id="footer" className="footer">
        <div className="copyright">
            &copy; Copyright <strong><span>YOUTHFP</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
            <a>AfriYAN Cameroun</a>. All Rights Reserved by
        </div>
    </footer>;
}