import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {logOutUser} from "../configurations/features/authSliceUser";

export function useTitle(title) {
    useEffect(() => {
        const AppName = " | YouthFP"
        const data = title+AppName;
        return () => {
            document.title = data
        }
    })
}
export function useFirstConnexion() {
    const {user, accessToken} = useSelector(state => state.auth);
    const nav = useNavigate();
    const dispatch = useDispatch()
    useEffect(() => {
        if (accessToken == null) {
            dispatch(logOutUser(""));
            nav('/login');
            window.location.reload();
        }
        if (user == null) {
            dispatch(logOutUser(""));
            nav('/login');
            window.location.reload();
        }
        if (user.isFirstConnexion == false) {
            nav('/reset');
            window.location.reload();
        }
    });

}
export function useMenuAdmin() {
    const {user, accessToken} = useSelector(state => state.auth);
    const nav = useNavigate();
    const dispatch = useDispatch();
    let view = false;
    useEffect(() => {
        if (accessToken == null) {
            view = false;
        }
        if (user == null) {
            view = false;
        }
        if(user != null){
            if (user.isFirstConnexion == false) {
                view = false;
            }
        }
    });
    return view;

}

export function isConnect() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {accessToken} = useSelector(state => state.auth);
    return accessToken != null;

}

export function isUserConnect() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {accessToken} = useSelector(state => state.authUser);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const nav = useNavigate();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (accessToken == null) {
            nav("/login");
        }
    });

}