import React, {useEffect, useState} from "react";
import {isUserConnect, useTitle} from "../../utils/Utils";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";

export default function ListServices(){
    const [services, setServices]=useState([]);
    const {id}= useParams();
    useTitle("Service");
    isUserConnect();
    const {user, accessToken} = useSelector(state => state.authUser);

    function checkIfUserIsConnect() {
        window.location='/';
    }

    const fetchService = ()=>{
        let preloader = document.getElementById('preloader');
        preloader.classList.remove("preloader-deactivate");
        axiosInstance(accessToken).get('service/list-by-cp-id/'+id)
            .then(function (response) {
                let fetchedData = [];
                if(response.data.length <=0){
                    fetchedData.push(
                        <tr key={0}>
                            <td colSpan="5">
                                <h3>Aucun Service trouvee</h3>
                            </td>
                        </tr>
                    );
                }else {
                    for (let index = 0; index < response.data.length; index++) {
                        let date = new Date(response.data[index].dateRdv);
                        let day = String(date.getDate()).padStart(2, '0');
                        let month = String(date.getMonth() + 1).padStart(2, '0');
                        let year = date.getFullYear();
                        let formattedDate = `${day}-${month}-${year}`;
                        fetchedData.push(
                            <tr key={(index+1)}>
                                <th scope="row">{index + 1}</th>
                                <td><a className="text-primary fw-bold">{response.data[index].libelle}</a></td>
                                <td className="fw-bold">{response.data[index].description}</td>
                                <td className="fw-bold">{response.data[index].centrePartenaire.libelle}</td>
                                <td><a className="text-primary" href={response.data[index].id+"/take-meeting"}>
                                    <button type="button" className="btn btn-secondary"><i className="bi bi-calendar"></i></button>
                                </a></td>
                            </tr>);
                    }
                }
                setServices(fetchedData);
                preloader.classList.add("preloader-deactivate");
            })
            .catch(function (error) {
                let fetchedData = [];
                fetchedData.push(<tr key={0}>
                    <td colSpan="5">
                        <h3>Impossible d'obtenir les informations</h3>
                    </td>
                </tr>);
                setServices(fetchedData);
                preloader.classList.add("preloader-deactivate");
            });
    }

    useEffect(
        ()=>{
            if(user===null)
                checkIfUserIsConnect();
            else
                fetchService();
        },[]
    );


    return <main id="main" className="main">
        <div className="pagetitle">
            <h1>Services</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Services</li>
                </ol>
            </nav>
        </div>
        <section className="section dashboard">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="card top-selling overflow-auto">
                                <div className="card-body pb-0">
                                    <h5 className="card-title">Services <span></span></h5>
                                    <table className="table table-borderless">
                                        <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Libelle</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Centre P.</th>
                                            <th scope="col">Rendez-vous</th>
                                        </tr>
                                        </thead>
                                        <tbody id="table">
                                        {services}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>;
}