import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {useSelector} from "react-redux";
import {useTitle} from "../../../utils/Utils";

export default function ReadArticle() {
    const {id} = useParams();
    useTitle("Article");
    const [read, setRead] = useState([]);
    const [informations, setInformations] = useState("");
    const paragraph = {width: '45%'};
    const {user, accessToken} = useSelector(state => state.authUser);
    const contain= {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        margin:'0 auto'
    };

    function checkIfUserIsConnect() {
        window.location='/';
    }
    const convertContenuToJson = (data) => {
        try {
            if (data.contenu) {
                const contenuJson = JSON.parse(data.contenu);
                data.contenu = contenuJson;
            }
        } catch (error) {
        }
        return data;
    }
    const fetchArticle = ()=>{
        let preloader = document.getElementById('preloader');
        preloader.classList.remove("preloader-deactivate");
        axiosInstance().get('api/article/get/'+ id)
            .then(function (response) {
                let data = response.data;
                if((data == null)||(data.length===0)){
                    document.getElementById('error').style.display='block';
                    document.getElementById('preloader').classList.add('preloader-deactivate');
                    setRead([]);
                }else{
                    const contenu = convertContenuToJson(data).contenu;
                    let fetchedData = [];
                    document.title=data.title;
                    setInformations(data.categorie);
                    fetchedData.push(<><h1>{data.title}</h1><br /></>);
                    contenu.blocks.forEach(block => {
                        if (block.type === 'unstyled') {
                            fetchedData.push(<><p>{block.text}</p></>);
                        } else if (block.type === 'unordered-list-item') {
                            fetchedData.push(<><ul><li>{block.text}</li></ul></>);
                        }

                    });

                    // Parse the date string into a Date object
                    const date = new Date(data.date);

                    // Extract day, month, and year from the Date object
                    const day = date.getUTCDate().toString().padStart(2, '0'); // getUTCDate() gives day of the month
                    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // getUTCMonth() returns month (0-11)
                    const year = date.getUTCFullYear(); // getUTCFullYear() returns full year

                    // Format the date as dd/mm/yyyy
                    const formattedDate = `${day}/${month}/${year}`;
                    fetchedData.push(<br />);
                    fetchedData.push(<hr />);
                    fetchedData.push(<div className="contain" style={contain}>
                        <div style={paragraph}>
                            <strong>Auteur: </strong>{data.author}
                        </div>
                        <div style={paragraph}>
                            <strong>Lue: </strong>{data.lue} fois
                        </div>
                        <div style={paragraph}>
                            <strong>Date Publication: </strong>{formattedDate}
                        </div>
                    </div>);
                    fetchedData.push(<br />);
                    fetchedData.push(<a href={'https://api.whatsapp.com/send?text=' + window.location.href}><i
                        className='fa fa-whatsapp' style={
                        {
                            color: '#128c7e'
                        }
                    }></i></a>);
                    document.getElementById('error').style.display='none';
                    setRead(fetchedData);
                }
                preloader.classList.add("preloader-deactivate");
            })
            .catch(function (error) {
                document.getElementById('error').style.display='none';
                preloader.classList.add("preloader-deactivate");
                setRead([]);
            });
    }

    useEffect(
        ()=>{
            if (user===null)
                checkIfUserIsConnect();
            else
                fetchArticle();
        },
        []
    )



    return  <main id="main" className="main">
        <div className="pagetitle">
            <h1>Article</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item ">
                        <a href={"/"}>{informations}</a>
                    </li>
                </ol>
            </nav>
        </div>
        <section className="section profile">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body pt-3 align-items-center flex-column">
                            <div className="row">
                                <div className="col-12 p-2" id="articles">
                                </div>
                                <div id="error">
                                    <p>Cet Articles n'existe pas / plus</p>
                                </div>
                                {read}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>;

}