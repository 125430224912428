import React, {useEffect, useState} from "react";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {useSelector} from "react-redux";
import {isUserConnect, useTitle} from "../../utils/Utils";

export default function Meeting() {
    const [drv, setRdv]=useState([]);
    useTitle("Rendez-vous");
    isUserConnect();
    const {user, accessToken} = useSelector(state => state.authUser);

    function checkIfUserIsConnect() {
            window.location='/';
    }

    const fetchRdv = ()=>{
        axiosInstance(accessToken).get('rendezvous/getByuserId/'+ user.id)
            .then(function (response) {
                let fetchedData = [];
                if(response.data.length <=0){
                    fetchedData.push(
                        <tr key={0}>
                            <td colSpan="7">
                                <h3>Aucune souscription trouvee</h3>
                            </td>
                        </tr>
                    );
                }else {
                    for (let index = 0; index < response.data.length; index++) {
                        let date = new Date(response.data[index].dateRdv);
                        let day = String(date.getDate()).padStart(2, '0');
                        let month = String(date.getMonth() + 1).padStart(2, '0');
                        let year = date.getFullYear();
                        let formattedDate = `${day}-${month}-${year}`;
                        fetchedData.push(
                            <tr key={(index+1)}>
                                <th scope="row">
                                    <a className="icon show" aria-expanded="true"><i
                                        className="bi bi-three-dots"></i></a>
                                </th>
                                <td>{response.data[index].libelle}</td>
                                <td><a className="text-primary">{response.data[index]?.serviceEntity?.libelle}</a></td>
                                <td><a className="text-primary">{response.data[index].centrePartenaire.libelle}</a></td>
                                <td><a className="text-primary">{formattedDate}</a></td>
                                <td><a className="text-primary">{response.data[index].heureDebut}</a></td>
                                <td><span className={(response.data[index].rdv != " PRIS") ? "badge bg-success" :"badge bg-warning"}>{response.data[index].rdv}</span></td>
                            </tr>
                        );
                    }
                }
                setRdv(fetchedData);
            })
            .catch(function (error) {
                let fetchedData = [];
                fetchedData.push(<tr key={0}>
                        <td colSpan="7">
                          <h3>Impossible d'obtenir les informations</h3>
                        </td>
                      </tr>);
                setRdv(fetchedData);
            });
    }

    useEffect(
        ()=>{
            if(user===null)
                checkIfUserIsConnect();
            else
                fetchRdv();
        },[]
    );
    return <main id="main" className="main">
        <div className="pagetitle">
            <h1>Mes Rendez-vous</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Mes Rendez-vous</li>
                </ol>
            </nav>
        </div>
        <section className="section dashboard">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="card recent-sales overflow-auto">
                                <div className="card-body">
                                    <h5 className="card-title">Liste</h5>

                                    <table className="table table-borderless">
                                        <thead>
                                        <tr key={-1}>
                                            <th scope="col">#</th>
                                            <th scope="col">Libelle</th>
                                            <th scope="col">Service</th>
                                            <th scope="col">Centre P.</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Heure</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody id="table">
                                        {drv}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>;
}