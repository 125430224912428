import { configureStore } from '@reduxjs/toolkit';
import authSliceUser from "../features/authSliceUser";


const store = configureStore({
    reducer: {
        authUser: authSliceUser,
    },
});

export default store